<template>
    <el-dialog title="Skapa ikon" :visible="visible" width="50%" :before-close="handleClose">
        <div v-loading="$waiting.is('loadingEditor')">
            <el-row :gutter="10" class="flex space-y-2" v-for="(localIcon, index) in icons" :key="index">
                <el-col :span="3" class="mt-2"><el-button size="large" style="font-size: 15px;" v-if="localIcon.code" :style="{ color: localIcon.color }" :icon="localIcon.code" circle/></el-col>
                <el-col class="w-full"><el-input size="large" v-model="localIcon.code"/></el-col>
                <el-col :span="15"><el-input size="large" v-model="localIcon.name"/></el-col>
                <el-col :span="15">
                    <div class="flex">
                        <el-select size="large" v-model="localIcon.color" class="pr-2">
                            <el-option v-for="color in colorOptions" :key="color.code" :label="color.label" :value="color.code" />
                        </el-select>
                        <input class="w-6 cursor-pointer" type="color" :value="localIcon.color" disabled />
                    </div>
                </el-col>
                <el-col :span="3"><i @click="deleteIcon(localIcon)" class="el-icon-delete cursor-pointer" v-if="localIcon.canDelete"/></el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSave">Spara</el-button>
                <el-button class="mr-3" @click="handleClose">Avbryt</el-button>
                <el-button icon="el-icon-plus" @click="AddIcon()" />
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Api from "../../accountCategories.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        iconOptions: {
            type: Array,
        },
    },

    watch: {
        iconOptions: {
            async handler() {
                this.icons = [...this.iconOptions];
            },
        },
    },

    data() {
        return {
            icons: [],
            colorOptions: [
                { code: "#73c68b", label: "Green" },
                { code: "#ff7878", label: "Red" },
                { code: "#ffb73a", label: "Yellow" },
                { code: "#14abf4", label: "Blue" },
                { code: "#ffa37d", label: "Orange" },
                { code: "#9986f2", label: "Purple" },
                { code: "#cccccc", label: "Grey" },
            ],
        };
    },

    methods: {
        AddIcon() {
            this.icons.push({ id: 0, code: "", color: "#cccccc" });
        },

        async deleteIcon(icon) {
            const confirm = await this.$dialog.title("Ta bort ikon " + icon.code).confirm("Är du säker att du vill ta bort icon - " + icon.code + " ?");
            if (!confirm) return;
            this.$waiting.start("loadingEditor");
            await Api.deleteIcon(icon.id);
            this.$waiting.end("loadingEditor");
            this.icons = this.icons.filter(item => item.id !== icon.id);
        },

        handleClose() {
            this.$emit("close");
        },

        async onSave() {
            let list = this.icons.filter(icon => icon.code !== "" && icon.code !== undefined);
            this.$waiting.start("loadingEditor");
            await Api.updateIcons(list);
            this.$waiting.end("loadingEditor");
            this.$notify.success({ title: "Utfört" });
            this.$emit("close");
        },
    },
};
</script>
<style scoped>
.dialog-footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
}
</style>
